@font-face {
  font-family: 'BalooBhaijaan2';
  src: url('fonts/BalooBhaijaan2-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BalooBhaijaan2';
  src: url('fonts/BalooBhaijaan2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BalooBhaijaan2';
  src: url('fonts/BalooBhaijaan2-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BalooBhaijaan2';
  src: url('fonts/BalooBhaijaan2-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BalooBhaijaan2';
  src: url('fonts/BalooBhaijaan2-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
